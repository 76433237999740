import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';

import { NotificationApi } from '../apis/notification';
import { PAGE_SIZE } from '../config';
import { PaginatedList } from '../libs/paginated-list';
import { Notification } from '../models/notification';
import { selectNotification, selectNotifications, setNotification, setNotifications } from '../store/notification';
import { AppState } from '../store/symbols';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  notificationPage: PaginatedList<Notification> = new PaginatedList<Notification>(PAGE_SIZE);
  notifications$: Observable<Notification[]>;

  private api = inject(NotificationApi);
  private store = inject<Store<AppState>>(Store);

  constructor() {
    this.notifications$ = this.store.pipe(select(selectNotifications));
  }

  selectNotification(id: string): Observable<Notification> {
    return this.store.pipe(select(selectNotification(id)));
  }

  fetchNotifications(init: boolean = false): Observable<Notification[]> {
    const page = init ? 1 : this.notificationPage.page + 1;
    return this.api.fetchNotifications(page, PAGE_SIZE).pipe(
      tap((notifications) => {
        this.store.dispatch(setNotifications(notifications, init));
        this.notificationPage.page = page;
        this.notificationPage.setPage(notifications);
      }),
    );
  }

  fetchNotification(id: string): Observable<Notification> {
    return this.api.fetchNotification(id).pipe(
      tap((notification) => {
        this.store.dispatch(setNotification({ notification }));
      }),
    );
  }
}
