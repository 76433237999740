import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';

import { Notification } from '../models/notification';

export const setNotifications = createAction(
  '[Notification] SetNotifications',
  (targetNotifications: Notification[], init: boolean = false) => ({ targetNotifications, init }),
);
export const setNotification = createAction('[Notification] SetNotification', props<{ notification: Notification }>());

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>();

export type State = EntityState<Notification>;
export const initialState: State = adapter.getInitialState();

export const notificationReducer = createReducer(
  initialState,
  on(setNotifications, (state, { targetNotifications, init }) =>
    init ? adapter.setAll(targetNotifications, state) : adapter.addMany(targetNotifications, state),
  ),
  on(setNotification, (state, { notification }) => adapter.upsertOne(notification, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return notificationReducer(state, action);
}

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectFeature = createFeatureSelector<State>('notification');
export const selectNotificationEntities = createSelector(selectFeature, selectEntities);
export const selectNotifications = createSelector(selectFeature, selectAll);
export const selectNotification = (id: string) =>
  createSelector(selectNotificationEntities, (entities) => entities[id]);
