import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Notification } from '../models/notification';

@Injectable({ providedIn: 'root' })
export class NotificationApi {
  private readonly http = inject(HttpClient);

  private baseUrl = 'v1/public/news_items';

  fetchNotifications(page: number, perPage: number): Observable<Notification[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Notification[]>(`${this.baseUrl}`, { params });
  }

  fetchNotification(notificationId: string): Observable<Notification> {
    return this.http.get<Notification>(`${this.baseUrl}/${notificationId}`);
  }
}
