<ng-container *slLoading="notification$ | async as notification">
  <sl-breadcrumb>
    <sl-breadcrumb-item path="/notification">{{ 'shared.breadcrumbs.notification' | locale }}</sl-breadcrumb-item>
    <sl-breadcrumb-item>{{ notification?.name }}</sl-breadcrumb-item>
  </sl-breadcrumb>

  <sl-layout size="small" class="news">
    <div class="heading">
      <div class="heading-date">{{ notification?.publishedAt | localDate: 'mediumDate' }}</div>
      <div class="heading-title">{{ notification?.name }}</div>
    </div>

    <tbx-wysiwyg-body class="body" [body]="notification?.body"></tbx-wysiwyg-body>

    <div class="back-button">
      <sl-button type="stroked" slBackButton defaultHref="/notification">{{
        'shared.navigation.back' | locale
      }}</sl-button>
    </div>
  </sl-layout>
</ng-container>
