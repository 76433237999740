import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { Observable } from 'rxjs';

import { EmptyComponent } from '../../../components/empty/empty.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { NotificationItemComponent } from '../../../components/notification/notification-item/notification-item.component';
import { PageHeaderComponent } from '../../../components/page-header/page-header.component';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Notification } from '../../../models/notification';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'page-notification-root',
  templateUrl: './notification-root.page.html',
  styleUrls: ['./notification-root.page.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    LoadingDirective,
    LayoutComponent,
    InfiniteScrollDirective,
    NotificationItemComponent,
    RouterLink,
    EmptyComponent,
    AsyncPipe,
    LocalePipe,
  ],
})
export class NotificationRootPage implements OnInit {
  notifications$: Observable<Notification[]>;
  loading = true;
  loadingMore = false;

  private readonly notificationService = inject(NotificationService);

  constructor() {
    this.notifications$ = this.notificationService.notifications$;
  }

  ngOnInit(): void {
    this.loading = true;
    this.notificationService.fetchNotifications(true).subscribe(() => {
      this.loading = false;
    });
  }

  onScroll(): void {
    if (!this.loadingMore && !this.notificationService.notificationPage.isComplete) {
      this.loadingMore = true;
      this.notificationService.fetchNotifications().subscribe(() => {
        this.loadingMore = false;
      });
    }
  }
}
