<sl-page-header>{{ 'shared.breadcrumbs.notification' | locale }}</sl-page-header>

<ng-container *slLoading="!loading">
  <sl-layout size="small" infiniteScroll (scrolled)="onScroll()">
    <div class="news-list">
      @if (notifications$ | async; as notifications) {
        @if (notifications?.length) {
          @for (notification of notifications; track notification.id) {
            <sl-notification-item [notification]="notification" [routerLink]="notification?.id"></sl-notification-item>
          }
        } @else {
          <sl-empty>{{ 'pages.notificationRoot.emptyMessage' | locale }}</sl-empty>
        }
      }
    </div>
  </sl-layout>
</ng-container>
