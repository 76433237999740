import { Routes } from '@angular/router';

import { NotificationDetailPage } from './notification-detail/notification-detail.page';
import { NotificationRootPage } from './notification-root/notification-root.page';

export const notificationRoutes: Routes = [
  {
    path: '',
    component: NotificationRootPage,
  },
  {
    path: ':notificationId',
    component: NotificationDetailPage,
  },
];

export default notificationRoutes;
