import { AsyncPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WysiwygBodyComponent } from '@twogate-npm/toolbox-angular';
import { Observable, tap } from 'rxjs';

import { BreadcrumbItemComponent } from '../../../components/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from '../../../components/breadcrumb/breadcrumb/breadcrumb.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { LayoutComponent } from '../../../components/layout/layout.component';
import { BackButtonDirective } from '../../../directives/back-button.directive';
import { LoadingDirective } from '../../../directives/loading.directive';
import { Notification } from '../../../models/notification';
import { LocalDatePipe } from '../../../pipes/local-date.pipe';
import { LocalePipe } from '../../../pipes/locale.pipe';
import { LocaleService } from '../../../services/locale.service';
import { NotificationService } from '../../../services/notification.service';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'page-notification-detail',
  templateUrl: './notification-detail.page.html',
  styleUrls: ['./notification-detail.page.scss'],
  standalone: true,
  imports: [
    LoadingDirective,
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    LayoutComponent,
    WysiwygBodyComponent,
    ButtonComponent,
    BackButtonDirective,
    AsyncPipe,
    LocalDatePipe,
    LocalePipe,
  ],
})
export class NotificationDetailPage implements OnInit {
  notificationId: string;
  notification$: Observable<Notification>;
  loading = true;

  private readonly route = inject(ActivatedRoute);
  private readonly notificationService = inject(NotificationService);
  private readonly titleService = inject(TitleService);
  private readonly router = inject(Router);
  private readonly localeService = inject(LocaleService);

  constructor() {
    this.notificationId = this.route.snapshot.paramMap.get('notificationId');
    this.notification$ = this.notificationService
      .selectNotification(this.notificationId)
      .pipe(
        tap((notification) =>
          this.titleService.setTitle(
            `${notification?.name} - ${this.localeService.translate('shared.breadcrumbs.notification')}`,
          ),
        ),
      );
  }

  ngOnInit(): void {
    this.loading = true;
    this.notificationService.fetchNotification(this.notificationId).subscribe(
      () => {
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.router.navigateByUrl('/notification');
        } else {
          throw error;
        }
      },
    );
  }
}
